<template>
  <div>
    <v-card>
      <v-card-title>
        Transfers
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="transferCollection"
        :search="search"
        :sort-by="['tr_number']"
        sort-desc="true"
        @click:row="handleClickItem"
      >
        <template #header="{ props: { headers } }">
          <thead class="v-data-table-header">
            <tr>
              <th></th>
              <th>
                <v-select
                  v-model="sourceStorageNameFilter"
                  :items="[
                    { source_storage_name: 'Select' },
                    ...sourceStorageNameCollection
                  ]"
                  item-text="source_storage_name"
                  item-value="source_storage_name"
                ></v-select>
              </th>
              <th></th>
              <th>
                <v-select
                  v-model="destinationStorageNameFilter"
                  :items="[
                    { destination_storage_name: 'Select' },
                    ...destinationStorageNameCollection
                  ]"
                  item-text="destination_storage_name"
                  item-value="destination_storage_name"
                ></v-select>
              </th>
              <th></th>
              <th>
                <v-select
                  v-model="nameFilter"
                  :items="[{ owner_name: 'Select' }, ...nameCollection]"
                  item-text="owner_name"
                  item-value="owner_name"
                ></v-select>
              </th>
              <th>
                <v-select
                  v-if="statuses.stock"
                  v-model="statusFilter"
                  :items="[
                    { key: null, value: 'Select' },
                    ...statuses.stock.transfer
                  ]"
                  item-text="value"
                  item-value="key"
                  return-object
                ></v-select>
              </th>
              <th></th>
            </tr>
          </thead>
        </template>
        <template v-slot:item.status="{ item }">
          <span v-if="statuses.stock.transfer && item.status >= 0">
            {{ statuses.stock.transfer.find(i => i.key == item.status).value }}
          </span>
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <TransferModalForm
              ref="transferlist"
              :modalData="modalData"
              :permissions="permissions"
              @closeModalForm="handleCloseModalForm"
              @saveModalForm="handleSaveModalForm"
              @new="handleNew"
            ></TransferModalForm>
            <v-spacer></v-spacer>

            <!-- <v-checkbox
              v-model="showAll"
              label="Active departments"
            ></v-checkbox> -->

            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline"
                  >Are you sure you want to delete this item?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >Cancel</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <!-- <template v-slot:item.status="{ item }">
          <span v-if="statuses.system">{{
            statuses.companySettings.department[item.status].value
          }}</span>
        </template> -->
        <template v-slot:item.actions="{ item }">
          <!-- <router-link
            :to="`/stock/transfers/${item.id}`"
            v-if="permissionCan('view')"
          >
            <v-icon small class="mr-2"> mdi mdi-eye </v-icon>
          </router-link> -->
          <v-icon
            v-if="permissionCan('delete') && item.deletable"
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ApiService from "@/core/services/api.service";
import TransferModalForm, { initialFormData } from "./TransferModalForm";

export const ENDPOINT = "stock/transfer/";

export default {
  name: "transfers",
  components: { TransferModalForm },
  data() {
    return {
      showAll: false,
      search: "",
      permissions: [],
      headers: [
        {
          text: "Transfer ID",
          value: "tr_number"
        },
        {
          text: "Source storage",
          value: "source_storage_name",
          filter: value => {
            if (this.sourceStorageNameFilter == "Select") return true;
            return value == this.sourceStorageNameFilter;
          }
        },
        {
          text: "Source schedule",
          value: "scheduled_release_date",
          width: "180px"
        },
        {
          text: "Destination storage",
          value: "destination_storage_name",
          filter: value => {
            if (this.destinationStorageNameFilter == "Select") return true;
            return value == this.destinationStorageNameFilter;
          }
        },
        {
          text: "Destination schedule",
          value: "scheduled_receive_date",
          width: "180px"
        },
        {
          text: "User name",
          value: "owner_name",
          filter: value => {
            if (this.nameFilter == "Select") return true;
            return value == this.nameFilter;
          }
        },
        {
          text: "Status",
          value: "status",
          filter: value => {
            if (!this.statusFilter.key && this.statusFilter.key != 0)
              return true;
            return value == this.statusFilter.key;
          },
          width: "150px"
        },
        {
          text: "Date",
          value: "created_at"
        },
        // { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false }
      ],
      sourceStorageNameFilter: "Select",
      destinationStorageNameFilter: "Select",
      statusFilter: { key: null, value: "Select" },
      nameFilter: "Select",
      modalData: {
        dialog: false,
        editedId: null
      },
      editedItem: Object.assign({}, initialFormData),

      dialogDelete: false
    };
  },

  computed: {
    ...mapGetters(["transferCollection", "statuses"]),

    nameCollection() {
      return this.transferCollection.map(item => {
        return item.owner_name;
      });
    },

    sourceStorageNameCollection() {
      return this.transferCollection.map(item => {
        return item.source_storage_name;
      });
    },
    destinationStorageNameCollection() {
      return this.transferCollection.map(item => {
        return item.destination_storage_name;
      });
    }
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    "$route.params.id": {
      handler: function(id) {
        if (id) {
          this.editItem(id);
        }
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    ...mapActions(["fetchTransfer"]),
    editItem(id) {
      this.$nextTick(function() {
        this.modalData.editedId = id;
        this.modalData.dialog = true;
      });
    },

    handleNew() {
      this.modalData.editedId = null;
      this.modalData.dialog = true;
    },

    handleSaveModalForm() {
      this.handleCloseModalForm();
    },

    handleCloseModalForm() {
      this.modalData.dialog = false;
      this.fetchTransfer();

      if (this.$route.name !== "transfers")
        this.$router.push({ name: "transfers" });
    },

    deleteItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      ApiService.delete(ENDPOINT + this.editedItem.id)
        .then(() => {
          this.fetchTransfer();
        })
        .catch(error => {
          console.log("Error!: ", error);
        });
      this.closeDelete();
    },

    handleClickItem(item) {
      if (this.permissionCan("view") && !this.dialogDelete) {
        this.$router.push(`/stock/transfers/${item.id}`);
      }
    },

    closeDelete() {
      // this.editedItem = Object.assign({}, initialFormData);
      this.modalData.editedId = null;
      this.dialogDelete = false;
    },

    permissionCan(action) {
      return this.permissions.find(x => x.name == "stock.transfer." + action);
    },

    setPermissions() {
      if (localStorage.permissions) {
        this.permissions = JSON.parse(localStorage.permissions).filter(
          item => item.name.indexOf("transfer") > -1
        );
      }
    }
  },

  async mounted() {
    if (
      !this.$refs.transferlist ||
      !this.$refs.transferlist.handleCloseModalForm
    ) {
      location.reload();
    }
    this.fetchTransfer();
    this.setPermissions();
  }
};
</script>
