var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',[_c('v-card-title',[_vm._v(" Transfers "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.transferCollection,"search":_vm.search,"sort-by":['tr_number'],"sort-desc":"true"},on:{"click:row":_vm.handleClickItem},scopedSlots:_vm._u([{key:"header",fn:function({ props: { headers } }){return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',[_c('th'),_c('th',[_c('v-select',{attrs:{"items":[
                  { source_storage_name: 'Select' },
                  ..._vm.sourceStorageNameCollection
                ],"item-text":"source_storage_name","item-value":"source_storage_name"},model:{value:(_vm.sourceStorageNameFilter),callback:function ($$v) {_vm.sourceStorageNameFilter=$$v},expression:"sourceStorageNameFilter"}})],1),_c('th'),_c('th',[_c('v-select',{attrs:{"items":[
                  { destination_storage_name: 'Select' },
                  ..._vm.destinationStorageNameCollection
                ],"item-text":"destination_storage_name","item-value":"destination_storage_name"},model:{value:(_vm.destinationStorageNameFilter),callback:function ($$v) {_vm.destinationStorageNameFilter=$$v},expression:"destinationStorageNameFilter"}})],1),_c('th'),_c('th',[_c('v-select',{attrs:{"items":[{ owner_name: 'Select' }, ..._vm.nameCollection],"item-text":"owner_name","item-value":"owner_name"},model:{value:(_vm.nameFilter),callback:function ($$v) {_vm.nameFilter=$$v},expression:"nameFilter"}})],1),_c('th',[(_vm.statuses.stock)?_c('v-select',{attrs:{"items":[
                  { key: null, value: 'Select' },
                  ..._vm.statuses.stock.transfer
                ],"item-text":"value","item-value":"key","return-object":""},model:{value:(_vm.statusFilter),callback:function ($$v) {_vm.statusFilter=$$v},expression:"statusFilter"}}):_vm._e()],1),_c('th')])])]}},{key:"item.status",fn:function({ item }){return [(_vm.statuses.stock.transfer && item.status >= 0)?_c('span',[_vm._v(" "+_vm._s(_vm.statuses.stock.transfer.find(i => i.key == item.status).value)+" ")]):_vm._e()]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('TransferModalForm',{ref:"transferlist",attrs:{"modalData":_vm.modalData,"permissions":_vm.permissions},on:{"closeModalForm":_vm.handleCloseModalForm,"saveModalForm":_vm.handleSaveModalForm,"new":_vm.handleNew}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Are you sure you want to delete this item?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function({ item }){return [(_vm.permissionCan('delete') && item.deletable)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }